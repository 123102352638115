/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {Helmet} from 'react-helmet';
import Navbar from "../components/Navbar";
import {containerMobFullStyles, containerStyles, mainContentStyles, pageTitleStyles} from "../styles/OtherStyles";
import UserTable from "../components/UserTable";
import StatsTitle from "../components/StatsTitle";
import Footer from "../components/Footer";
import {css} from "@emotion/react";

const Stats: FC = () => {
    return (
        <div>
            <Helmet>
                <title>Stats</title>
                <link rel="canonical" href="https://post2earn.ad-yo.com/stats"/>
            </Helmet>
            <Navbar/>
            <div css={mainContentStyles}>
                <div css={containerStyles}>
                    <div css={css`;
                        width: 100%;
                        margin-top: 44px;
                    `}>
                        <h1 css={pageTitleStyles}>Top Users</h1>
                    </div>
                </div>

                <div css={css`${containerStyles}; ${containerMobFullStyles}; margin-top: 28px;`}>
                    <StatsTitle/>
                    <UserTable/>
                </div>

                <div css={containerStyles}>
                <Footer/>
                </div>
            </div>
        </div>
    );
};

export default Stats;